.hlist {
	counter-reset: size-as-heading;

	h2,
	h3,
	h4 {
		font-size: 1.1em;
		font-family: var(--font-main);
		font-weight: 600;
		line-height: 1.5;
		margin-top: 1em;
		&:before {
			color: var(--clr-navy);
			content: counter(size-as-heading) ".";
			counter-increment: size-as-heading;
			display: inline-block;
			/*
			font-family: var(--font-heading);
			*/
			margin-right: .25em;
		}
	}

	p {
		margin: 0 0 1.25em;
	}
}

.entry-content .hlist p:first-of-type {
	margin-top: 0;
}
