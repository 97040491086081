.alignleft,
.alignnone,
.alignright {
	margin-top: 0;
	img {
		max-width: 50%;
	}
}

.alignleft {
	float: left;
	margin-right: 1em;
}

.alignright {
	float: right;
	margin-left: 1em;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.entry-title {
	line-height: 1.3;
}

.entry-footer,
.entry-header,
.entry-summary,
.comments-area,
.entry-content {
	max-width: 45em;
	margin: 1em auto;
	padding: 0 1rem;
	width: 100%;
}

.entry-footer {
	overflow: auto;
	position: relative;
	/*
	text-align: right;
	*/
	span {
		display: block;
		&.edit-link {
			float: right;
		}
	}
}

.entry-meta {
	font-size: small;
}

article {
	header {
		text-align: center;
	}
	/*
	iframe {
		min-width: 100%;
	}
	*/
	ul {
		list-style: disc;
	}
	ol {
		list-style: decimal;;
	}
	ol,
	ul {
		margin-top: .5em;
		margin-left: 1.5em;
	}
	li {
		margin-top: .5em;
	}
	p:empty {
		display: none;
	}
	&.page {
		margin-bottom: 2em;
		margin-top: 2em;
		/*
		header {
			text-align: left;
		}
		*/
	}
}

.search {
	article {
		margin-bottom: 2em;
		margin-top: 2em;
	}
}

.page-header {
	text-align: center;
}

.nav {
	&-links {
		background: #333;
		display: flex;
		flex-wrap: wrap;
		font-size: .8em;
		> div {
			background: #000;
			flex: 1;
			text-align: center;
		}
		a {
			color: #ddd;
			display: block;
			font-weight: 600;
			padding: 1em;
			text-decoration: none;
			text-transform: uppercase;
			&:focus,
			&:hover {
				color: #fff;
			}
		}
		@media (--med) {
			font-size: 1em;
		}
	}
	&-next {
		a:after {
			content: "\00a0\00bb";
		}
	}
	&-previous {
		a:before {
			content: "\00ab\00a0";
		}
	}
	@media (--med) {
		font-size: 1em;
		&-next {
			border-bottom-left-radius: 4em;
		}
		&-previous {
			border-bottom-right-radius: 4em;
		}
	}
}

.yarpp-related {
	clear: both;
	position: relative;
	h3 {
		padding-top: 2em !important;
	}
}

.yarpp-modified {
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	width: 100%;

	* {
		margin-top: 0;
	}

	figure {
		background: #f3f3f3;
		border: 1px solid #eee;
		border-radius: 5px;
		flex: 1 0 150px;
		justify-content: space-between;
		margin: .5em .25rem;
		padding: .5em;
	}

	figcaption {
		font-size: .8em;
		margin: 0 auto 1em;
		max-width: 150px;
		/*
		text-align: left;
		*/
		a {
			color: #333;
			display: block;
			font-weight: 600;
			&:hover {
				color: #c60;;
			}
		}
	}
}

.coc_dcl {
	color: #fff;
}

/*
#disqus_thread {
	margin: 1em auto;
	max-width: var(--widthL) !important;
}
*/

blockquote {
	padding: 2rem;
}

.comment-respond {
	form {
		margin-top: 0;
		padding: .5em;
	}
	label {
		display: block;
		&:after {
			content: ":";
		}
	}
	p {
		margin-top: .5em;
	}
	textarea,
	[type="email"],
	[type="text"],
	[type="url"] {
		background: #f9f9f9;
		border: 1px solid #ccc;
		border-radius: 5px;
		margin-top: 0;
		padding: .5em;
		width: 100%;
		&:focus {
			background: #fff;
			border: 999;
		}
	}
	[type="submit"] {
		background: #789;
		border: 1px solid #888;
		border-radius: 5px;
		color: #fff;
		font-weight: 600;
		margin-top: 1em;
		padding: .25em 1em;
		transition: background 300ms;
		&:hover {
			background: green;
			cursor: pointer;
		}
	}
	.form-submit {
		text-align: center;
	}
}

.comment-list {
	margin-bottom: 1em;
	li {
		padding: .25em;
	}
}
