.screen-reader-text,
.vh {
	@apply --visually-hidden;
}

.vh {
	@media (--lg) {
		&_lg {
			@apply --visually-hidden;
		}
	}
}

.a11y {
	&_skip {
		clip: rect(1px 1px 1px 1px);
		height: 1px;
		overflow: hidden;
		position: absolute;
		transform: translateY(-100%);
		transition: transform .5s cubic-bezier(.18, .89, .32, 1.28);
		white-space: nowrap;
		width: 1px;
		&:focus {
			background-color: var(--color-links);
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			clip: auto;
			color: #fff;
			font-size: 1em;
			height: auto;
			left: 50%;
			margin: 0 0 0 -100px;
			outline: 0;
			overflow: visible;
			padding: .8em 0 .7em;
			position: fixed;
			text-align: center;
			text-decoration: none;
			top: -.3em;
			transform: translateY(0%);
			white-space: normal;
			width: 200px;
		}
	}
}

/*
[role="alert"] {
}
*/
/*
.vhlg {
	@media (--med) {
		@apply --visually-hidden;
	}
}
*/

/* have screen reader pause before an abbreviation */
/*
.at-pause::after {
	content: ".";
	opacity: 0;
}
*/

