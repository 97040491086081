.colList {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 1em;
	* {
		margin-top: 1em;
		padding: 0;
		text-align: center;
	}
}
