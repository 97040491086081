@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

body {
	animation: fadeIn .3s both;
	animation-delay: .15s;
}

