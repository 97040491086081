.tabtain {
	margin-top: 2rem;
	/*
	overflow: auto;
	position: relative;
	*/
	[aria-hidden="true"] {
		opacity: 0;
		position: absolute;
		top: -1000%;
	}
}

[role="tablist"] {
	align-content: stretch;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	font-size: .8em;
	justify-content: center;
	list-style: none;
	margin: 0 0 2em;
	@media (--med) {
		font-size: initial;
	}
	a {
		border: 1px solid #999;
		border-radius: 5px;
		color: #333;
		display: block;
		font-family: var(--font-heading);
		padding: .25em .5em;
		text-align: center;
		text-decoration: none;
		&:focus,
		&:hover,
		&[aria-current="location"] {
			background: var(--clr-brick);
			color: #fff;
		}
		&:focus,
		&:hover {
			opacity: .75;
		}
	}
	li {
		flex: 1;
		margin: .25em;
	}
}

[role="tabpanel"] {
	transition: opacity 300ms;
}
