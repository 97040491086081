.wrap {
	> article,
	> header {
		margin-left: auto;
		margin-right: auto;
		max-width: var(--widthL);
		width: 100%;
	}

	> article:first-child {
		margin-top: 1em;
	}

	> header {
		margin-top: 2em;
	}
}

