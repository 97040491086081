html {
	font-family: var(--font-system-sans);
	font-size: var(--native-font-size);
	&.fonts-loaded {
		font-family: var(--font-main);
		font-size: var(--base-font-size);
	}
}

.menu,
h1,
h2,
h3,
h4 {
	font-family: var(--font-serif);
}

.fonts-loaded {
	.menu,
	h1,
	h2,
	h3,
	h4 {
		font-family: var(--font-heading);
	}

}

