.media {
	height: 0;
	margin-top: 1em;
	overflow: hidden;
	padding: 0 0 56.25%;
	position: relative;

	iframe,
	object {
		height: 100%;
		left: 0;
		margin-top: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

iframe {
	margin: 0 auto;
}

.vid {
	background-color: #000;
	background-position: 50% 50%;
	background-size: cover;
	font-size: .8em;
	height: 0;
	/*
	margin: 1rem 0 2rem;
	*/
	margin: 1.7em 0;
	min-width: 100%;
	overflow: hidden;
	padding: 56.25% 0 0;
	position: relative;

	button,
	button::before,
	figcaption,
	iframe {
		margin-top: 0;
		position: absolute;
	}

	button,
	button::before {
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
	}

	button {
		background: #900;
		border: none;
		border-radius: 30%;
		font-size: .8em;
		font-weight: 600;
		min-height: 4em;
		min-width: 5em;
		padding: .5em;
		transition: background 300ms;
		&::before {
			content: "";
			border-style: solid;
			border-width: 1em 0 1em 2em;
			border-color: transparent transparent transparent #fff;
			left: 55%;
		}
		@media (--med) {
			font-size: initial;
			opacity: .75;
			&:hover {
				background: #b33;
				opacity: 1;
			}
		}
	}

	iframe {
		height: 100%;
		left: 0;
		margin: 0 auto;
		top: 0;
		width: 100%;
	}

	figcaption {
		background: #000;
		bottom: 0;
		color: #fff;
		padding: .5em;
		text-align: center;
		width: 100%;
	}

	@media (--med) {
		font-size: initial;
	}

}

li .vid {
	margin: .5rem 0 2rem;
}

.figimg {
	margin: 1.7rem 0;

	figcaption {
		font-size: small;
		margin: 0;
		text-align: center;
	}

	img {
		min-width: 100%;
	}
}
