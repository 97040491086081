.pg {
	figure {
		background: #222;
		margin: 0 auto .5em;
		max-width: 300px;
		overflow: hidden;
		position: relative;
	}

	figcaption {
		margin: 0 auto;
		text-align: center;
	}

	img {
		display: block;
		min-height: 300px;
		object-fit: cover;
	}

	a {
		height: 100%;
		left: 0;
		position: absolute;
		text-decoration: none;
		top: 0;
		transition: all .5s linear;
		width: 100%;
		&:focus,
		&:hover {
			h2 {
				background: rgba(0, 0, 0, .7);
				color: #fff;
			}
			&:before {
				background: transparent;
			}
		}
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(10, 50, 80, .6);
		}
	}

	h2 {
		background: rgba(0, 0, 0, .5);
		color: #eee;
		display: inline-block;
		font-family: var(--font-main);
		font-size: 1em;
		font-weight: 600;
		line-height: 1.2;
		margin-top: auto;
		padding: 1.5em 1.5em;
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		transform: translate3d(-50%, -50%, 0);
		width: 100%;
		@media (--med_lg) {
			font-size: 1em;
		}
	}

	@media (--med) {
		align-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 auto;
		max-width: var(--widthXL);
		padding: 0 .25em 1em;
		text-align: center;

		a:before {
			transition: all .3s linear;
		}

		/*
		a {
			&:hover {
				h2 {
					background: rgba(0, 0, 0, .7);
					color: #fff;
				}
				&:before {
					background: transparent;
				}
			}
		}
		*/
		
		figure {
			display: inline-block;
			flex: 0 1 300px;
			margin: .5em;
			transition: all .3s linear;
			overflow: hidden;
			&:hover {
				background: #000;
				box-shadow: 4px 4px 7px rgba(0, 0, 0, .25);
				/* transform: scale(1.05); z-index: 1; */
				img {
					filter: unset;
					transform: scale(1.06);
				}
			}
			img {
				filter: brightness(50%);
			}
		}

		h2 {
			background: transparent;
			font-size: 1.2rem;
		}
	}
}
