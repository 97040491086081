.mast {
	background-color: var(--clr-mh);
	background-image: linear-gradient(90deg, rgba(65, 92, 113, .25), rgba(65, 92, 113, .25)), url("../i/nav_bg.jpg");
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-size: cover;
	padding: .5rem 0;
	position: relative;
	position: sticky;
	top: 0;

	button {
		line-height: 1;
		margin: .5vw .25vw 0;
		padding: .5rem;
		transition: all .3s linear;
		svg {
			fill: #fff;
			height: 1.5rem;
			width: 1.5rem;
			.close {
				display: none;
			}
		}
		&.nav_toggle svg {
			stroke: #fff;
		}
	}

	button[aria-expanded="true"] svg {
		.open {
			display: none;
		}
		.close {
			display: block;
		}
	}

	@media (--lg) {
		padding: .5rem;
		width: 100%;
	}

	&.scroll {
		background-color: var(--clr-mh);
	}
	
	&.homebg {
		background-color: transparent;
		/*
		background-image: linear-gradient(90deg, rgba(65, 92, 113, .25), rgba(65, 92, 113, .25)), url("../i/banner.jpg");
		*/
		background-image: url("../i/banner.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 100% 50%;
		position: relative;
	}

}

button {
	background: transparent;
	/*
	border: 0;
	border: 2px solid transparent;
	border-radius: 5px;
	*/
	cursor: pointer;
	margin: 0 auto; 
	svg {
		transition: fill 500ms;
	}
	&:hover,
	&:focus {
		svg {
			fill: #eee;
		}
	}
	/*
	&:focus {
		border-color: #fff;
	}
	*/
}

.totop {
	background-image: linear-gradient(180deg, rgba(255, 255, 255, .85), rgba(255, 255, 255, .45));
	border-radius: 25%;
	bottom: .5em;
	color: #333;
	display: inline-block;
	font-size: 1.2em;
	font-weight: 700;
	line-height: 1;
	margin: 0;
	padding: .125em .5em .25em;
	position: fixed;
	right: .5em;
	text-decoration: none;
	&:hover {
		color: #000;
		text-decoration: none;
	}
	@media (--med) {
		opacity: .5;
		&:hover {
			opacity: 1;
		}
	}
}
