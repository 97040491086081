.wl,
.wxl {
	margin: auto;
}

.wxl {
	max-width: var(--widthXL);
}

.wl {
	max-width: var(--widthL);
}

.wrap {
	section {
		margin-top: 0;
	}
}

/*
article {
	p,
	ul,
	ol {
		padding-left: 1em;
		padding-right: 1em;
	}
	p + img {
		padding-left: 0;
		padding-right: 0;
	}
}
*/

.vid + * {
	clear: both;
	margin-top: 2em;
}

.flx {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: 1rem;
}


.herowrap {
	align-content: center;
	align-items: center;
	justify-content: flex-start;
	display: flex;
	/*
	margin: 1em auto 0;
	*/
	margin: 0 auto;
	max-width: 100%;
	/*
	padding: 1rem;
	*/
	position: relative;
	@media (--med_lg) {
		max-width: 60vw;
	}
	@media (--lg) {
		max-width: 50vw;
	}
	&.blck {
		display: block;
		/*
		padding: 0;
		margin: 0;
		*/
	}
}

/*
 * olga in header
 */

.olga {
	.herowrap {
		min-height: calc(100vw * .5625);
		@media (--med_lg) {
			margin-left: 5vw;
			min-height: calc(60vw * .5625);
		}
		@media (--lg) {
			margin-left: 25vw;
			min-height: calc(50vw * .5625);
		}
	}

	figure {
		display: block;
		text-align: center;
	}

	h2 {
		color: #124;
		font-size: 1.1rem;
		font-weight: 400;
		line-height: 1.4;
		margin-top: .5em;
		max-width: 60%;
		padding: 0 .5em;
		@media (--med) {
			max-width: unset;
		}
	}

	figcaption {
		font-size: .9rem;
		margin-top: 0;
	}

	@media (--med) {
		h2 {
			font-size: calc(.4em + 1.9vw);
			padding: .5em;
		}
	}
}


/*
 * featured in
 */

.features {
	margin: 0 auto;
	max-width: var(--widthL);
	padding: 1em 0;

	ul {
		align-items: center;
		display: flex;
		flex: 1;
		flex-wrap: wrap;
		justify-content: space-around;
		margin: 0;
		max-width: var(--widthL);
		@media (--med) {
			flex: 1 1 auto;
			flex-wrap: nowrap;
		}
	}

	li {
		margin: .125em 2vw;
		max-width: 40%;
		text-align: center;
	}

	@media (--med) {
		flex-direction: row;
		justify-content: center;
	}
}


/*
 * book section
 */

.book {
	background-color: var(--clr-brick);
	color: #fff;
	padding: 0;
	.wrapper {
		padding: 1em 0 0;
		> div {
			padding: 0 1em;
			width: 100%;
			&.blck {
				padding: 0;
			}
			&:last-child {
				background-color: var(--clr-dkbrk);
			}
		}
	}

	figure {
		align-items: center;
		display: flex;
		/*
		flex: 1;
		*/
		justify-content: center;
		margin: 1em 0;
		width: 100%;
		&.vid {
			margin: 0;
		}
	}

	figcaption {
		/*
		flex: 0 1 45%;
		*/
		text-align: center;
		padding-right: 1.5rem;
		p {
			font-size: .9em;
			@media (--med) {
				font-size: 1.1em;
			}
		}
	}

	.fig {
		/*
		flex: 1 1 55%;
		width: 60%;
		*/
	}

	p,
	h2,
	h3 {
		color: #fff;
		margin-top: 0;
		/*
		padding-left: 1rem;
		*/
	}

	h2 {
		font-size: 1.6rem;
		text-align: center;
		@media (--lg) {
			font-size: 2.2rem;
		}
	}

	/*
	h3 {
		font-size: 1.4rem;
		@media (--lg) {
			font-size: 2rem;
		}
	}
	*/

	p {
		font-size: .9rem;
		@media (--lg) {
			font-size: 1rem;
		}
	}

	img {
		display: block;
		margin: 0 auto;
		/* transform: rotate(3deg); */
	}

	.populist {
		margin: 0 auto 4rem;
		max-width: 30rem;
		padding-top: 1em;
		li {
			background-position: 0 .5em;
			background-repeat: no-repeat;
			padding: 0 1em 2em 4em;
		}
		h3 {
			font-family: var(--font-main);
			font-size: 1.2rem;
			@media (--lg) {
				font-size: 1.4rem;
			}
		}
		p {
			/*
			line-height: 1.5;
			*/
			@media (--lg) {
				font-size: 1.1rem;
			}
		}
		.iperson {
			background-image: url("../i/icon-person.png");
		}
		.itrophy {
			background-image: url("../i/icon-trophy.png");
		}
		.iplayer {
			background-image: url("../i/icon-player.png");
		}
	}

	a {
		color: #fff;
		text-decoration: none;
		&:focus,
		&:hover {
			text-decoration: underline;
		}
		&.bttn {
			&:hover {
				background: rgba(0, 0, 0, .25);
				text-decoration: none;
			}
		}
	}

	@media (--med) {
		.wrapper {
			align-content: center;
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 auto;
			max-width: 100vw;
			padding: 1em;
			> div:last-child {
				background-color: unset;
			}
		}
	}

	@media (--med_lg) {
		background-image: linear-gradient(180deg, var(--clr-brick), var(--clr-brick) 50%, var(--clr-dkbrk) 50%, var(--clr-dkbrk) 100%);
		.wrapper {
			flex-direction: column;
		}
	}

	@media (--lg) {
		background-image: linear-gradient(90deg, var(--clr-brick), var(--clr-brick) 50%, var(--clr-dkbrk) 50%, var(--clr-dkbrk) 100%);
		.wrapper {
			flex-direction: row;
			> div {
				flex: 1;
			}
		}
	}
	/*
	@media (--xl) {
		.wrapper {
			max-width: 80vw;
		}
	}
	*/
}


/*
.herowrap.blck {
	padding: 0 0 0 2rem;
}
*/

/*
 * signup
 */

.signup {
	background: var(--clr-bluegrey);

	blockquote {
		margin: 0 auto;
		max-width: 40em;
		padding: 0 1em 2em;
		p {
			font-family: var(--font-heading);
			font-size: 1.2em;
		}
		footer p {
			font-family: var(--font-main);
			font-size: 1em;
			font-style: italic;
			text-align: right;
		}
		@media (--med) {
			padding: .5em 2em 2em;
		}
	}
}


/*
 * blog posts
 */

.blogPosts {
	background: #fff;
	padding: 1em 0;
	text-align: center;
	h2 {
		color: #124;
		text-align: center;
		margin-bottom: .5em;
	}
}

.viewall {
	margin-top: 0;
	text-align: center;
	a.bttn {
		border-color: var(--clr-navy);;
		color: var(--clr-navy);
		display: inline-block;
		text-decoration: none;
		&:focus,
		&:hover {
			background: var(--clr-navy);
			color: #fff;
		}
	}
}
