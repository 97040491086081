.bq {
	background-image: linear-gradient(180deg, #fff, #f3f3f3);
	border-left: 8px solid var(--clr-brick);
	margin: 1.5em 1em 1.5em .5em;
	padding: 1em 1.5em;
	p {
		font-family: var(--font-serif);
		font-style: italic;
	}
	footer {
		font-size: small;
		/*
		font-style: italic;
		*/
		margin-top: .25em;
		text-align: right;
	}
	@media (--med) {
		font-size: 1.2em;
	}
}
