:root {

	--html-height: 100%;
	--html-width: 100%;
	/**
	 * max width in pixels
	 */
	--max-px-width: 1140;
	/*
	 * custom colors
	--color-links: #5c918f;
	--color-links: #af9b63;
	 */
	--color-base: #fff;
	--color-links: #5076b8;
	--color-hlinks: #5096d8;
	--color-headings: #5d6358;
	--color-selection: #fffefc;
	--color-text: #3e3e3e;
	--color-footer: #4f4f4f;

	/*
	--clr-mh: #7d9aaa;
	--clr-mh: #768d9f;
	--clr-mha: rgb(123, 152, 168, .25);
	*/
	--clr-mh: rgb(105, 132, 153);
	--clr-mha: rgb(105, 132, 153, .9);
	--clr-mhalt: rgb(165, 192, 213, .9);
	--clr-hdng: #2e425a;
	--clr-bluegrey: #90aeb9;
	--clr-brick: #cf6766;
	--clr-dkbrk: #b55a59;
	--clr-navy: #30415d;
	--clr-sub: #879fab;

	--hover-links: var(--color-headers);
	--bg-selection: var(--color-links);

	--color-blue: #52aebd;
	--color-blue2: #328e9d;
	--color-green: #a7bc5f;
	--color-green2: #8c9d51;
	--color-orange: #e58a2d;
	--color-gray: #f9f8f6;
	--color-orange2: #e08a36;

	/*
	--color-taupe: #5e645a;
	*/
	--color-taupe: #3e443a;
	--color-gold: #af9c61;

	/*
	 * fonts
	 */
	--mob-font-size: 90%; /* 16px */
	--base-font-size: 100%; /* 16px */
	--native-font-size: 105%;
	/*
	--small-font-size: 75%;
	--med-font-size: 110%;
	--lg-font-size: 120%;
	--huge-font-size: 130%;
	*/
	--heading-font-weight: normal;
	--line-height: 1.6;

	/*
	 * families
	--font-mast-cinzel: "Cinzel", georgia, serif;
	--font-header: "Muli", var(--font-main);
	--font-main: "Raleway", helvetica, sans-serif;
	--font-heading: "Martel", serif;
	--font-main: "Open Sans", helvetica, sans-serif;
	--font-heading: "Times New Roman", Times, serif;
	--font-heading: "Bitter", Times, serif;
	--font-main: "Open Sans", sans-serif;
	--font-main: var(--font-system-sans);
	--font-heading: "Times New Roman", Times, serif;
	 */
	--font-system-sans: system-ui, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	--font-serif: "Georgia", "Times New Roman", Times, serif;
	--font-heading: "Bitter", var(--font-serif);
	--font-main: "Open Sans", var(--font-system-sans);

	/*
	 * dimensions
	 */
	/*
	--widthS: 30rem; 
	--widthM: 48rem; 
	*/
	--widthM: 37.5rem; 
	--widthL: 62.5rem; 
	--widthXL: 80rem; 
	/*
	--widthXL: 80rem; 
	--max-mob: var(--widthS);
	--wrapper-max: var(--widthXL);
	--wrapper-max: calc((var(--max-px-width) / 16) * 1rem);
	*/
	--wrapper-max: var(--widthL);

	/*
	--button-width: 2.4em;
	--button-height: 2.4em;
	*/

	--no-topmarg {
		margin-top: 0;
	}

	--visually-hidden {
		border: 0; 
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

}

@custom-media --landscape screen and (orientation: landscape);
/* small */
/*
@custom-media --sm screen and (width < 30em);
@custom-media --sm_landscape screen and (width < 30em) and (orientation: landscape);
@custom-media --sm_portrait screen and (width < 30em) and (orientation: portrait);
*/
/* small - medium */
/*
@custom-media --sm_med screen and (width >= 30em) and (width < 48em);
@custom-media --sm_med_landscape screen and (width >= 30em) and (width < 48em) and (orientation: landscape);
@custom-media --sm_med_portrait screen and (width >= 30em) and (width < 48em) and (orientation: portrait);
*/
/* medium */
@custom-media --med screen and (width >= 37.5em);
@custom-media --med_landscape screen and (width >= 37.5em) and (orientation: landscape);
@custom-media --med_portrait screen and (width >= 37.5em) and (orientation: portrait);

/* medium - large */
@custom-media --med_lg screen and (width >= 37.5em) and (width < 62.5em); 
@custom-media --med_lg_landscape screen and (width >= 48em) and (width < 62.5em) and (orientation: landscape);
@custom-media --med_lg_portrait screen and (width >= 48em) and (width < 62.5em) and (orientation: portrait);
/* medium - xlarge */
/*
@custom-media --med_xl screen and (width >= 48em) and (width < 80em); 
@custom-media --med_xl_landscape screen and (width >= 48em) and (width < 80em) and (orientation: landscape);
@custom-media --med_xl_portrait screen and (width >= 48em) and (width < 80em) and (orientation: portrait);
*/
/* large */
@custom-media --lg screen and (width >= 62.5em); /* 1000px */
@custom-media --lg_landscape screen and (width >= 62.5em) and (orientation: landscape);
@custom-media --lg_portrait screen and (width >= 62.5em) and (orientation: portrait);

/* lg - xl */
/*
@custom-media --lg_xl screen and (width >= 62.5em) and (width < 80em);
@custom-media --lg_xl_landscape screen and (width >= 62.5em) and (width < 80em) and (orientation: landscape);
@custom-media --lg_xl_portrait screen and (width >= 62.5em) and (width < 80em) and (orientation: portrait);
*/
/*
@custom-media --lg_xl screen and (width >= 62.5em) and (width < 72.9375em);
@custom-media --lg_xl_landscape screen and (width >= 62.5em) and (width < 72.9375em) and (orientation: landscape);
@custom-media --lg_xl_portrait screen and (width >= 62.5em) and (width < 72.9375em) and (orientation: portrait);
*/
/* extra large */
@custom-media --xl screen and (width >= 80em);
/* nav tweak */
/*
@custom-media --navbp screen and (width >= 56.25em);
*/
