.site-footer {
	/*
	background: #222;
	*/
	background-image: linear-gradient(to bottom, #31405d, #111);
	color: #fff;
	margin-top: 0;
	padding: 2em 1em;
	position: relative;
	text-align: center;
	a {
		color: #69d7ff;
		&:focus,
		&:hover {
			color: #fff;
		}
	}
}

.fmenu {
	li {
		display: inline;
		&:not(:first-child):before {
			content: " | ";
		}
	}
	a {
		/*
		color: #fff;
		*/
		padding: 0 .25em;
		text-transform: lowercase;
	}
	@media (--med) {
		display: inline-block;
		margin-left: .5em;
		li:before {
			content: " | ";
		}
	}
}

.fblk {
	align-self: flex-start;
	margin: 1em 0 2em;
	/*
	margin-top: 0;
	float: left;
	width: 33%;
	*/
	max-width: 420px;
	/*
	a {
		color: #69d7ff;
	}
	*/
	p {
		text-transform: uppercase;
	}
}

/**
 * twitter
 */
.twit {
	/*
	&_head {
		color: #eee;
		display: block;
		font-size: 1.5em;
		font-weight: 600;
		margin-bottom: .5em;
		text-transform: uppercase;
	}
	*/
	&_feed {
		padding: 0 2em;
		li {
			/*
			line-height: 1.3;
			*/
			padding-bottom: .5em;
		}
		@media (--med) {
			font-size: 1.1em;
		}
	}
}

.soc {
	display: flex;
	justify-content: center;
	li {
		margin: .5em;
	}
}
/*
.tweet .at,
.tweet {
	font-weight: 600;
}

.tweet {
	a {
		font-weight: 600;
	}
}

.at {
	font-weight: 600;
}
*/

.copy {
	clear: both;
	font-size: .8em;
	margin-top: 2rem;
	width: 100%;
}
