.nav {
	&-primary {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;

		h1 {
			/*
			align-self: center;
			*/
			display: inline-block;
			line-height: 1;
			margin-top: 0;
			flex: 1;
			font-size: 1em;
			padding: 0 .5rem .125rem;
			text-align: center;
			img {
				display: block;
				margin: 0 auto;
				max-width: 100%;
			}
		}

		@media (--lg) {
			align-items: flex-end;
			justify-content: center;
			max-width: var(--widthXL);
			margin: 0 auto;
			h1 {
				flex: 0 1 326px;
			}
		}

		&.sticky {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
		}
	}
}

.menu {
	background: var(--clr-mha);
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;
	left: 0;
	margin-top: 0;
	opacity: 0;
	padding-top: 3rem;
	position: absolute;
	right: 0;
	top: 74px;
	top: 0;
	transition: transform .2s ease-out 0s, opacity .2s ease-out .1s;
	transform: translateX(-100%);
	&.opened {
		opacity: 1;
		pointer-events: all;
		transition: transform .3s ease-out 0s, opacity .15s ease-out 0s;
		transform: translateX(0);
	}

	a {
		color: #fff;
		display: inline-block;
		font-size: 1.1em;
		line-height: 1;
		padding: 1rem 1.5rem;
		position: relative;
		text-decoration: none;
		white-space: nowrap;
		width: 100%;
		&[aria-current],
		&:focus,
		&:hover {
			background: #333;
			background: var(--clr-navy);
			color: #fff;
		}
		&.hassub {
			&:after {
				content: "\0020\25BE";
				/*
				font-family: var(--font-system-sans);
				*/
				font-family: sans-serif;
				line-height: 1;
				opacity: .5;
			}
			&.opnd:after {
				/*
				content: "\0020\25B4";
				*/
				content: "\0020\2013";
			}
		}
	}
	li {
		text-align: left;
		width: 100%;
	}

	@media (--lg) {
		background: transparent;
		/*
		flex: 0 1 70%;
		*/
		flex: 1;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-evenly;
		opacity: 1;
		padding-top: 0;
		position: relative;
		top: 0;
		transform: unset;
		> a {
			background: transparent;
			border-radius: 5px;
			color: #fff;
		}
		a {
			font-size: 1.2rem;
			line-height: 1;
			padding: .5rem 1rem;
			&[aria-current],
			&:focus,
			&:hover {
				background: transparent;
				/*
				background: rgba(255, 255, 255, .75);
				color: var(--clr-hdng);
				*/
				color: #20314d;
			}
			&:hover {
				.sub[aria-hidden] {
					display: block;
				}
			}
			/*
			&.hassub:after {
				&.opnd {
					&:after {
						content: "\0020\25B4";
					}
				}
			}
			*/
			/*
			&.hassub {
				border-radius: 5px 5px 0 0;
			}
			*/
		}
		> li {
			flex: 1;
			margin: 0 .125rem;
			position: relative;
			text-align: center;
			transition: transform .2s ease-out .2s, opacity .2s ease-out .2s;
			
			/*
			&:hover {
				background: rgba(255, 255, 255, .5);
				.sub[aria-hidden="true"] {
					opacity: 1;
					top: 2em;
					transition: transform .2s ease-out .2s, opacity .2s ease-out .2s;
				}
				> a {
					color: var(--clr-hdng);
				}
			}
			*/
		}
	}
}

.sub {
	margin: 0;
	opacity: 1;
	transition: opacity 300ms;
	&[aria-hidden="true"] {
		opacity: 0;
		position: absolute;
		top: -999em;
	}
	a {
		padding-left: 3em;
	}
	@media (--lg) {
		background: var(--clr-sub);
		border-radius: 5px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
		min-width: 100%;
		overflow: hidden;
		position: absolute;
		top: 44px;
		top: 2.6rem;
		transition: background-color color 300ms;
		a {
			border: unset;
			color: #eee;
			display: block;
			padding: .5em 1em;
			&:hover {
				background: var(--clr-navy);
				color: #fff;
			}
		}
	}
}

/*
@media (--med) {
	.menu {
		> li:hover {
			.sub[aria-hidden="true"] {
				opacity: 1;
				top: 2em;
			}
		}
	}
}
*/
