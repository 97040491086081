.srch {
	z-index: 7;
}

.nav_toggle {
	z-index: 6;
}

.a11y_skip {
	z-index: 5;
}

.menu,
.sub {
	z-index: 4;
}

.mast {
	z-index: 3;
}

.vbttn {
	z-index: 2;
}

.vid {
	button {
		z-index: 2;
	}
	figcaption {
		z-index: 1;
	}
}

[role="tablist"] {
	z-index: 1;
}

[role="tabpanel"] {
	z-index: -1;
}

/*
.menu {
	z-index: 1;
}
*/

