/*
 * default styles
 */

* {
	color: inherit;
	font-family: inherit;
	line-height: inherit;
	/*
	margin: 0;
	*/
}

* + * {
	margin-top: 1em;
}

body, br, li, dt, dd, th, td, option, main, div, a, span, img, header, nav {
	margin-top: 0;
}

body,
html {
	min-height: 100vh;
	/*
	overflow-x: hidden;
	@supports not (display: grid) {
		overflow-x: hidden;
	}
	*/
}

html {
	box-sizing: border-box;
	/*
	font-family: var(--font-main);
	font-size: var(--base-font-size);
	*/
}

/*
html,
body {
	overflow-x: hidden;
}
*/


*,
:after,
:before {
	box-sizing: inherit;
}

svg:not(:root) {
	overflow: hidden;
}

body {
	background-color: #fff;
	color: var(--color-text);
	display: flex;
	flex-direction: column;
	line-height: var(--line-height);
	margin: 0;
	/*
	transition: 500ms;
	*/
	&.noscroll {
		overflow: hidden;
	}
	/*
	&.home {
		background-image: url("../assets/i/banner.jpg");
		background-repeat: no-repeat;
		background-size: contain;
	}
	*/
}

main {
	flex: 1;
}

h1,
h2,
h3,
h4 {
	color: var(--clr-navy);
	/*
	font-family: var(--font-heading);
	line-height: 1.2;
	*/
}

h1 {
	font-size: 1.8rem;
	@media (--med) {
		font-size: 2.8em;
	}
}

h2 {
	font-size: 1.4em;
	@media (--med) {
		font-size: 1.6em;
	}
}

h3 {
	font-size: 1.3em;
	@media (--med) {
		font-size: 1.4em;
	}
}

h4 {
	font-size: 1.1em;
}

hr {
	border-bottom: 1px solid #eee;
	box-sizing: content-box;
	height: 0;
	/*
	margin: 0;
	*/
	overflow: visible;
	padding: 0;
}

img {
	height: auto;
	max-width: 100%;
}

/*
li,
p {
	line-height: 2;
}
*/

a {
	color: var(--color-links);
	font-weight: 400;
	text-decoration: none;
	transition: all 500ms ease-out;
	/*
	transition-delay: 0;
	*/
	&:focus,
	&:hover {
		color: var(--color-hlinks);
		text-decoration: underline;
	}
}

h1 a,
h2 a,
h3 a,
h4 a {
	display: block;
	color: var(--clr-navy);
}


/*
*:focus {
	outline: 1px dotted #444;
}
*/

i,
em {
	font-style: italic;
}

b,
strong {
	font-weight: 700;
}

.c {
	text-align: center;
}

::-moz-selection {
	color: var(--color-selection);
	background: var(--color-links);
	text-shadow: none;
}

::selection {
	color: var(--color-selection);
	background: var(--color-links);
	text-shadow: none;
}

[hidden] {
	display: none;
}
