.bttn {
	border-width: 1px;
	border-style: solid;
	border-radius: 5px;
	display: inline-block;
	font-weight: 600;
	margin-top: .5rem;
	padding: .25em .5em;
	text-align: center;
	transition: all .3s linear;
}

.order {
	background: var(--clr-brick);
	color: #fff;
	padding: .5em 1em;
	&.focus,
	&:hover {
		background: green;
		color: #fff;
		text-decoration: none;
	}
}

.vbttn {
	background: #000;
	border: none;
	border-radius: 50%;
	min-height: 3em;
	min-width: 3em;
	opacity: .75;
	padding: 1em;
	position: relative;
	transition: all .3s linear;
	&:focus,
	&:hover {
		opacity: 1;
	}
	&:hover {
		&:after {
			background: rgba(255, 255, 255, .5);
			border-radius: 5em;
			color: #000;
			content: " " attr(aria-labelledby);
			position: absolute;
			top: 3.25rem;
			left: 50%;
			display: inline-block;
			min-width: 8rem;
			transform: translateX(-50%);
		}
	}
	/*
	@media (--med) {
		font-size: initial;
		opacity: .75;
		&:hover {
			background: #b33;
			opacity: 1;
		}
	}
	*/
}

.vbttn:before {
	border-style: solid;
	border-width: .5em 0 .5em 1em;
	border-color: transparent transparent transparent #fff;
	content: "";
	position: absolute;
	top: 50%;
	left: 55%;
	transform: translate3d(-50%, -50%, 0);
}

.bookvid {
	background: #fff;
	border: 1px solid fff;
	&:before {
		border-color: transparent transparent transparent #b55a59;
	}
	/*
	@media (--med) {
		&:hover {
			background: #b33;
			border-color: #b33;
		}
	}
	*/
}

