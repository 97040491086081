form {
	padding: .5em;
}

.srch {
	align-content: center;
	align-items: center;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transition: transform .2s ease-out 0s, opacity .2s ease-out .1s;
	transform: translateY(-100%);
	width: 100%;
	&.opened {
		background: rgba(0, 0, 0, .55);
		opacity: 1;
		padding: 0 10vw;
		pointer-events: all;
		transition: transform .3s ease-out 0s, opacity .15s ease-out 0s;
		transform: translateY(0);
	}
	[role="search"] {
		align-content: center;
		align-items: center;
		background: rgba(0, 0, 0, .5);
		border-radius: 4px;
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: center;
		margin-top: 0;
	}
	[type="search"] {
		background: #fff;  
		border: 1px solid var(--color-taupe);
		flex: 1;
		margin-top: 0;
		padding: .75em;
		transition: all 500ms;
	}
	[type="submit"] {
		background: green;
		color: #fff;
		margin-top: 0;
		margin-right: auto;
		padding: .75em;
	}
	@media (--med) {
		font-size: 1.2em;
	}
}

.signup,
.sub_signup {
	color: #222;
	margin-top: 1.5em;
	> div {
		padding: 1em;
		text-align: center;
	}

	h2 {
		color: #124;
	}

	form,
	p {
		margin-top: 0;
	}

	button {
		background: var(--clr-navy);
		border-color: var(--clr-navy);
		color: #fff;
		margin-top: 0;
		&.busy {
			/*
			background: #eee;
			border-color: #ddd;
			color: #222;
			*/
			opacity: .25;
		}
	}
}

.sub_signup {
	background: #f3f3f3;
	background-image: linear-gradient(180deg, #fff, #f3f3f3);
	padding-bottom: 1em;
	h2 {
		text-align: center;
	}
}


.ck_subscribe_form {

	[type="text"],
	[type="email"] {
		background: #fff;
		border: 1px solid #aaa;
		border-radius: 5px;
		margin-top: 0;
		min-width: 100%;
		padding: .4rem .5rem;
		&:focus {
			border-color: var(--clr-navy);
		}
	}

	button {
		padding: .5rem 1em .75rem;
		line-height: 1;
		&:focus,
		&:hover {
			background: green;
			border-color: green;
		}
	}

	label {
		display: none;
	}
}

.grouper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (--med) {
		flex-direction: row;
	}
	> div {
		flex: 0 1 auto;
		padding: .5em 1vh;
	}
}
